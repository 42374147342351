/* src/components/AboutUs/styles.module.css */
.container {
    width: 100%;
    height: 60vh; /* Adjust the height as needed */
    position: relative;
    overflow: hidden; /* Ensures bubbles stay within the container */
    /*background-color: #f0f0f0; !* Light background for visibility *!*/
}

.bubble {
    width: 200px; /* Bubble size */
    height: 200px; /* Bubble size */
    border-radius: 50%; /* Makes the divs circular */
    position: absolute; /* Allows positioning within the container */
    background-color: #000000; /* Bubble color */
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 1); /* Adds shadow to the bubble */
    transition: left 7s cubic-bezier(1,1,1,1), top 7s cubic-bezier(1,1,1,1); /* Smooth transition for position changes */
}

.bubbleText {
    position: absolute;
    top: -0px;
    left: 50%;
    transform: translate(-50%, -50%);
    white-space: nowrap; /* Prevents text from wrapping */
    color: white;
    font-size: 32px;
    animation: fadeOut 5s ease forwards; /* Adjusted animation duration to 1s */
    z-index: 2;
}

@keyframes fadeOut {
    0% {
        opacity: 1;
        transform: translate(-50%, -0px);
    }
    100% {
        opacity: 0;
        visibility: hidden;
        transform: translate(-50%, -30px); /* Move up */
    }
}

.bubbleImg {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1; /* Ensure the image is below the text */
}

.modal {
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.50); /* Start slightly smaller */
    max-width: 90vw;
    width: 950px;
    height: 600px;
    padding: 40px;
    background-image: url('../../assets/images/modal4.png');
    background-size: 100% 100%;
    background-position: center;
    color: white;
    z-index: -1;
    display: flex;
    font-size: 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    opacity: 0; /* Start fully transparent */
    visibility: visible; /* Initially hidden, but this won't be animated */
    transition: opacity 1s ease, transform 1s ease; /* Apply transitions */

}

.modal.show {
    transform: translate(-50%, -50%) scale(1); /* Scale to normal size for animation */
    opacity: 1; /* Fully visible */
    visibility: visible; /* Instant change when modal is shown */
    z-index: 10; /* Hide the modal behind the bubbles */
    /* No transition delay specified for visibility as it does not animate smoothly */
}

.modal button {
    cursor: pointer;
    background-color: #0d88bd; /* A deep, rich brown */
    color: white; /* A golden color for the text */
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    margin-left: 50%;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease, transform 0.2s ease;
    background-image: linear-gradient(to bottom right, #0d88bd, #095373);
}

/* Separate hover selector for plain CSS */
.modal button:hover {
    background-color: #6d4c41; /* Lighten on hover */
    transform: translateY(-2px); /* Lift the button */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

/* Focus styles to improve accessibility */
.modal button:focus {
    outline: none;
    transform: translateY(1px); /* Slightly move down to simulate being pressed */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Shorten the shadow to simulate being pressed */

}

/* Class to add when the modal is active */

.modalText {
    margin: 0; /* Removes default margins */
    /* Removes default padding */
    padding: 240px 100px 12% 100px;
}

.modal button {
    margin-bottom: 15px;
    font-size: 24px;
}

@media (max-width: 770px) {
    .container {
        height: calc(100vh - 60px); /* Adjusted height */
    }
    .modal {
        top: 52%;
        height: 80vh; /* Adjusted height */
    }
    .modalText {
        margin: 0; /* Removes default margins */
        /* Removes default padding */
        padding: 130px 10% 10%;
        font-size: 20px;
    }
}