/* src/components/IconGrid/styles.module.css */
.iconGrid {
    display: grid;
    grid-template-columns: repeat(10, 1fr); /* Creates a 10-column grid */
    grid-template-rows: repeat(3, 1fr); /* Creates 3 rows */
    gap: 5px; /* Increased gap for fixed spacing */
    position: fixed;
    left: 50%;
    bottom: 20px;
    transform: translateX(-50%);
    width: auto; /* Adjust based on your preference */
    max-width: 95%; /* Ensure it doesn't stretch too much */
    padding: 5px; /* Padding around the grid */
}

.iconCell {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
}

.iconCell img {
    width: auto; /* Allows the image to scale based on height */
    max-width: 64px; /* Adjusts the maximum width of icons */
    height: 64px; /* Adjusts the height of icons */
    box-shadow: black;
    border: 2px solid black; /* Black border */
    border-radius: 9px; /* Rounded corners */
    transition: transform 0.1s ease, box-shadow 0.1s ease; /* Faster animation */
    display: block;
    /*box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.5); !* Adjusted to cast a shadow *!*/
    cursor: pointer; /* Indicates the icon is clickable */
}

.iconCell img.clicked {
    transition: transform 0.3s ease, box-shadow 0.1s ease; /* Faster animation */
    /* transform: scale(3) ; Simulates the button being pressed down */
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5); /* Adjusted for clicked state */
    opacity: 1;
    scale: 0.95;
}

.iconCell img:hover:not(.clicked) {
    /* transform: scale(1.2); */
    opacity: 0.85;
}

.iconCell:has(img.clicked) {
    background-color: rgb(0, 0, 0, 0);
    scale: 0.95;
}

/* Media query to hide the icon grid on mobile devices */
@media (max-width: 770px) {
    .iconGrid {
        display: none;
    }
}